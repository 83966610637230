import React from "react";
import { AppProvider } from "./src/context";
import GTM from '@src/utils/GTM.js';

export const onRouteUpdate = () => {
	GTM.handleRoute();

    // for FB Pixel event
    if( typeof window !== 'undefined' && window.fbq != null){
        window.fbq('dataProcessingOptions', ['LDU'], 0, 0);
        window.fbq("init"," 327590165071608");
        window.fbq('track', 'PageView');
    }
}

export const wrapRootElement = ({ element }) => (
    <AppProvider>{element}</AppProvider>
)
