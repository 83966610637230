export const isEDMode = typeof window !== 'undefined' ? window.location.href.indexOf('ed=1') > 0 : false;
export const tabletMedia = "(min-width: 0px) and (max-width: 1024px)";

export const matchesBreakpoint1280 = (media = "(min-width: 0px) and (max-width: 1279px)") => {
	if (typeof window !== 'undefined') {
		return window.matchMedia(media).matches;
	}
	return true;
};

export const isMobile = (media = "(min-width: 0px) and (max-width: 1200px)") => {
	if (typeof window !== 'undefined') {
		return window.matchMedia(media).matches;
	}
	return true;
};

export const isTablet = (media = tabletMedia) => {
	if (typeof window !== 'undefined') {
		return window.matchMedia(media).matches;
	}
	return true;
};

export const bounds = (elem) => {
	const rect = elem.getBoundingClientRect();

	return {
		top: rect.top,
		left: rect.left,
		right: rect.right,
		bottom: rect.bottom,
		width: rect.width,
		height: rect.height,
	};
};

export const getWinSize = () => {
	if (typeof window !== 'undefined') {
		const w = window;
		const d = document;
		const e = d.documentElement;
		const g = d.getElementsByTagName('body')[0];
		const x = w.innerWidth || e.clientWidth || g.clientWidth;
		const y = w.innerHeight || e.clientHeight || g.clientHeight;

		return { width: x, height: y };
	}

	return { width: 0, height: 0 };
};

export const inViewport = (elem, entireBox = false, xOffset = 0, yOffset = 0) => {
	if (typeof window !== 'undefined') {
		const bds = bounds(elem);
		const doc = getWinSize();

		if (!entireBox) {
			return bds.top >= 0 && bds.left >= 0 && bds.top <= doc.height && bds.left <= doc.width;
		} else {
			const topLimit = bds.top - doc.height
			const bottomLimit = bds.top + bds.height;
			const leftLimit = bds.left - doc.width;
			const rightLimit = bds.left + bds.width;

			if (topLimit <= 0 && bottomLimit >= 0 && leftLimit <= 0 && rightLimit >= 0) {
				return true;
			} else {
				return false;
			}
		}
	}

	return false;
};

export const debounce = (func, wait, immediate) => {
	let timeout = null;

	return function() {
		const context = this;
		const args = arguments;

		const later = () => {
			timeout = null;

			if (!immediate) {
				func.apply(context, args);
			}
		};

		const callNow = immediate && !timeout;

		clearTimeout(timeout);

		timeout = setTimeout(later, wait);

		if (callNow) {
			func.apply(context, args);
		}
	};
};

export const getOS = () => {
	let OSName = 'unknown-os';

	if (navigator.appVersion.includes('Win')) OSName = 'win';
	if (navigator.appVersion.includes('Mac')) OSName = 'mac';
	if (navigator.appVersion.includes('X11')) OSName = 'unix';
	if (navigator.appVersion.includes('iPhone')) OSName = 'ios';
	if (navigator.appVersion.includes('iPad')) OSName = 'ios';
	if (navigator.appVersion.includes('Linux')) OSName = 'linux';

	return OSName;
};

export const getCookieByName = (cookieName) => {
	const cookieValue = document.cookie.split('; ').find((row) => row.startsWith(cookieName + '='));
	if (cookieValue === undefined) {
		return null;
	}
	return cookieValue.split('=')[1];
};

export const focusLoop = (e, selector) => {
	const tabPressed = e.key === 'Tab' || e.keyCode === 9;

	if (!tabPressed) return;

	const elems = document.querySelectorAll(selector)

	if (elems?.length) {
		const firstElem = elems[0];
		const lastElem = elems[elems.length - 1];

		if (!Array.from(elems).includes(document.activeElement)) {
			firstElem.focus();
		}

		if (e.shiftKey) {
			if (document.activeElement === firstElem) {
				lastElem.focus();
				e.preventDefault();
			}
		} else {
			if (document.activeElement === lastElem) {
				firstElem.focus();
				e.preventDefault();
			}
		}
	}
}

export const getUrlParameter = (param) => {
    param = param.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + param + '=([^&#]*)');
    const results = regex.exec(window.location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
