import React, { useState } from "react"

const defaultState = {
    modalOpen: false,
    externalUrl: null,
    showModal: () => {},
    setExternalUrl: () => {},
    menuOpen: false,
    showMenu: () => {},
    videoModalOpen: false,
    showVideoModal: () => {},
    videoUrl: null,
    setVideoUrl: () => {},
    videoModalCaptions: null,
    setVideoModalCaptions: () => {},
    modalTrigger: null,
    setModalTrigger: () => {},
    videoEl: null,
    setVideoEl: () => {},
    stickyTrayMounted: false,
    widget: false,
    setWidget: () => {},
}

const AppContext = React.createContext(defaultState)

const AppProvider = ({ children }) => {
    const [modalOpen, showModal] = useState(defaultState.modalTrigger)
    const [url, setUrl] = useState(defaultState.externalUrl)
    const [menuOpen, showMenu] = useState(defaultState.menuOpen)
    const [videoModalOpen, showVideoModal] = useState(
        defaultState.videoModalOpen
    )
    const [videoUrl, setVideoUrl] = useState(defaultState.videoUrl)
    const [videoModalCaptions, setVideoModalCaptions] = useState(defaultState.videoModalCaptions)
    const [modalTrigger, setModalTrigger] = useState(defaultState.modalTrigger)
    const [videoEl, setVideoEl] = useState(defaultState.videoEl)
    const [stickyTrayMounted, setStickyTrayMounted] = useState(defaultState.stickyTrayMounted)
    const [widget, setWidget] = useState(defaultState.widget)

    const store = {
        modalOpen: modalOpen,
        showModal: showModal,
        externalUrl: url,
        setExternalUrl: setUrl,
        menuOpen: menuOpen,
        showMenu: showMenu,
        videoModalOpen: videoModalOpen,
        showVideoModal: showVideoModal,
        videoUrl: videoUrl,
        setVideoUrl: setVideoUrl,
        videoModalCaptions: videoModalCaptions,
        setVideoModalCaptions: setVideoModalCaptions,
        modalTrigger: modalTrigger,
        setModalTrigger: setModalTrigger,
        videoEl: videoEl,
        setVideoEl: setVideoEl,
        stickyTrayMounted: stickyTrayMounted,
        setStickyTrayMounted: setStickyTrayMounted,
        widget: widget,
        setWidget: setWidget,
    }

    return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}

export default AppContext

export { AppProvider }
